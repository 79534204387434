import * as React from "react"
import theme from './theme'
import {
  ChakraProvider,
  Heading, Box, Flex, Spacer, Link
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Wines } from './components/wines'
import { WineMenu } from './components/menu'
import Papa from 'papaparse'
import {Food} from './components/food'
import {Loader} from './components/loader'

export const App = () => {
  const [wines, setWines] = useState([])
  const [food, setFood] = useState([])
  const [initialWines, setInitialWines] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [lbvTab, setLbvTab] = useState('food')
  const [position, setPosition] = useState(window.pageYOffset)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTAJOUiJ_RLL515O73ugHPlK3oyoIhEFWMcNcAjLi485frFmfgCcXk-dUFdz4xKt6lWLpMBH-QrvGPO/pub?gid=0&output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        setWines(parseWines(results.data))
        setInitialWines(parseWines(results.data))
        Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTAJOUiJ_RLL515O73ugHPlK3oyoIhEFWMcNcAjLi485frFmfgCcXk-dUFdz4xKt6lWLpMBH-QrvGPO/pub?gid=1371948838&output=csv", {
          download: true,
          header: true,
          complete: (results) => {
            setFood(parseFood(results.data))
            setIsLoading(false)
          }
        })
      }
    })
  }, []);

  useEffect(()=> {
    const handleScroll = () => {
      let moving = window.pageYOffset

      setVisible(position > moving);
      setPosition(moving)
    };
    window.addEventListener("scroll", handleScroll);
    return(() => {
      window.removeEventListener("scroll", handleScroll);
    })
  })

  const parseWines = (rawWines) => rawWines.map(rawWine => ({
    domain: rawWine.domaine,
    origin: rawWine.origine,
    price: {
      away: rawWine.emporter,
      stay: rawWine.place
  },
    title: rawWine.title,
    type: rawWine.type,
    hr: rawWine.hr,
    bio: rawWine.bio
  }))

  const parseFood = (rawFood) => rawFood.map(rawFood => ({
    name: rawFood.nom,
    compo: rawFood.composition,
    price: rawFood.price,
    share: rawFood.partager,
    dessert: rawFood.dessert,
    plat: rawFood.plat,
    available: rawFood.available
  }))

  return (
    <ChakraProvider theme={theme}>
      <Box as='header' zIndex='100' transition='top 0.4s ease-out' top={visible ? '0' : '-100px'} position='fixed' backgroundColor='white' px='20px' width='100%'>
        <Heading as='h4' size='md' textAlign='left' pt='20px' pb='20px'>
          <Flex alignItems='center'>
            <Link href='https://www.labellevigne.com/' isExternal='true'>La Belle Vigne</Link>
            <Spacer />
            <WineMenu setLbvTab={ setLbvTab }/>
          </Flex>
        </Heading>
      </Box>
      <Box>
        {
          isLoading
            ? <Loader />
            : lbvTab === 'wine'
              ? <Wines isLoading={isLoading} visible={visible} wines={wines} setWines={setWines} initialWines={initialWines} />
              : <Food food={food}/>
        }
      </Box>
    </ChakraProvider>
  )
}
