import * as React from "react"
import {
  Box,
  Heading,
  Text
} from '@chakra-ui/react'

export const NoWine = () => (
  <Box textAlign='center'>
    <Heading as='h4'>Oh !</Heading>
    <Text as='h4'>Nous ne trouvons pas ce vin !</Text>
    <iframe src="https://giphy.com/embed/3h2AeAOj83j7slRkyW" width="324" height="430" frameBorder="0"></iframe>
  </Box>
)
