import * as React from "react"

import {
  Box,
  Button,
  Link,
  Stack
} from '@chakra-ui/react'

export const NoResult = ({ type }) => {
  const baseUrl = 'https://www.labellevigne.com/'
  const url = `${baseUrl}${type === 'wine' ? 'cave-a-vins' : 'cave-a-manger'}`
  return (
    <Box textAlign='center' mt='150px'>
      <Stack>
        <Box>
          Quelque chose d'embêtant s'est produit.
          Retrouvez notre carte sur le site labellevigne.com
        </Box>
        <Link href={url} isExternal='true'>
          <Button colorScheme='primary'> Voir la carte </Button>
        </Link>
      </Stack>
    </Box>
  )
}
