import * as React from "react"
import bg from '../assets/bg.jpeg'
import {
  Box,
  Stack,
  Text,
  Divider,
  Flex,
  Spacer,
  Center
} from '@chakra-ui/react'
import {NoResult} from './noResult'

export const Food = ({ food }) => {
  const availableFood = food.filter(food => food.available === '1')
  const plats = availableFood.filter(meal => meal.plat === '1')
  const partager = availableFood.filter(meal => meal.share === '1')
  const desserts = availableFood.filter(meal => meal.dessert === '1')

  return (
    <Center backgroundImage={bg}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat">
      { food.length === 0
        ? <NoResult type='food' />
        : <Box bg="rgba(255,255,255,0.7)">
        <Box mt='100px' px='30px' maxW='600px'>
          <Stack spacing={3}>
            <Text fontSize='3xl' color='primary.main' textTransform='uppercase' fontWeight='900'>À partager</Text>
            {partager.map((meal, i) => (
              <Flex key={i}>
                <Box mb='10px' paddingRight='30px'>
                  <Text fontSize='lg' fontWeight='700' textTransform='uppercase'>{meal.name}</Text>
                  <Text fontSize='md' fontWeight='500'>{meal.compo}</Text>
                </Box>
                <Spacer />
                <Box fontWeight='700'>
                  {meal.price}€
                </Box>
              </Flex>
            ))}
            <Divider p={4} />
            <Text fontSize='3xl' color='primary.main' textTransform='uppercase' fontWeight='900'>Nos plats</Text>
            {plats.map((meal, i) => (
              <Flex key={i}>
                <Box mb='10px' paddingRight='30px'>
                  <Text fontSize='lg' fontWeight='700' textTransform='uppercase'>{meal.name}</Text>
                  <Text fontSize='md' fontWeight='500'>{meal.compo}</Text>
                </Box>
                <Spacer />
                <Box fontWeight='700'>
                  {meal.price}€
                </Box>
              </Flex>
            ))}
            <Divider p={4} />
            <Text fontSize='3xl' color='primary.main' textTransform='uppercase' fontWeight='900'>Desserts</Text>
            {desserts.map((meal, i) => (
              <Flex key={i}>
                <Box mb='10px' paddingRight='30px'>
                  <Text fontSize='lg' fontWeight='700' textTransform='uppercase'>{meal.name}</Text>
                  <Text fontSize='md' fontWeight='500'>{meal.compo}</Text>
                </Box>
                <Spacer />
                <Box fontWeight='700'>
                  {meal.price}€
                </Box>
              </Flex>
            ))}
          </Stack>
        </Box>
        </Box>
      }
    </Center>
  )
}
