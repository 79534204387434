import * as React from "react"
import {
  Box,
  Tag,
  Flex,
  Spacer,
  Text, Image
} from '@chakra-ui/react'
import bio from '../assets/bio.png'



export const WineCard = ({ wine }) => {
  const color = wine.type === 'rouge'
    ? 'wine.rouge'
    : wine.type === 'blanc'
      ? 'wine.blanc'
      : wine.type === 'rose'
        ? 'wine.rose'
        : 'gray'

  return (
    <Box borderRadius='md' borderBottom='1px solid #E2E2E2' overflow='hidden' py='2' px='2' backgroundColor='white'>
      <Flex>
        <Box
          color='gray.500'
          fontSize='xs'
        >{wine.origin}</Box>
        <Spacer />
        <Box fontWeight='500' fontSize='sm'>{wine.price.away}€ &bull; {wine.price.stay}€</Box>
      </Flex>
      <Box
        mt='1'
        fontWeight='semibold'
        as='h3'
        lineHeight='tight'
        fontSize='sm'
        isTruncated
      >
        {wine.title}
      </Box>
      <Flex>
        <Box
          color='gray.500'
          fontSize='xs'
          textTransform='uppercase'
          fontWeight='semibold'
          mr='4px'
        >
          <Text>{wine.domain}</Text>
        </Box>
        {wine.bio === '1' ? <Image src={bio} alt='Vin bio pic saint loup' width='30px' /> : null}
        <Spacer />
        <Tag px='1' variant='solid' textColor={wine.type === 'rouge' ? 'white' : 'black'} backgroundColor={color} fontSize='8px' textAlign='center' alignSelf='end' textTransform='uppercase'>
          {wine.hr ? 'Hors region' : 'Region'}
        </Tag>
      </Flex>
    </Box>
  )
}

