import * as React from "react"
import {
  Box,
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'


export const WineMenu = ({ setLbvTab }) => (
  <Box>
    <Menu autoSelect={false} closeOnSelect={true}>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        Nos cartes
      </MenuButton>
      <MenuList type='radio'>
        <MenuItem onClick={() => setLbvTab('food')}>Cave à manger</MenuItem>
        <MenuItem onClick={() => setLbvTab('wine')}>Cave à vins</MenuItem>
      </MenuList>
    </Menu>
  </Box>
)
