import * as React from "react"
import loader from '../assets/loader.gif'

import {
  Box,
  Image,
  Center
} from '@chakra-ui/react'

export const Loader = () => (
  <Center>
    <Box textAlign='center' mt={['150px', '0']}>
      <Image src={loader} alt='Loading' />
    </Box>
  </Center>
)
