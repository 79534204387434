import * as React from "react"
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, SimpleGrid,
  Heading,
  Flex
} from '@chakra-ui/react'
import { WineCard } from './wine-card'

export const WineDispatcher = ({ wines }) => {
  const rouges = wines.filter(wine => wine.type === 'rouge')
  const roses = wines.filter(wine => wine.type === 'rose')
  const blancs = wines.filter(wine => wine.type === 'blanc')
  const bulles = wines.filter(wine => wine.type === 'bulles')

  return (
    <Accordion defaultIndex={[0]} width='100%' allowMultiple>
      <AccordionItem>
        <AccordionButton p='2px 0 2px 2px'>
          <Flex alignItems='center' flex={1} textAlign='left'>
            <Heading as='h4' size='sm' textTransform='uppercase'>Blancs</Heading>
            <Box borderRadius={1000} w='11px' h='11px' ml='10px' backgroundColor='wine.blanc'></Box>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0} pb={4}>
          <SimpleGrid columns={[1,2,3]} width='100%' spacingX='40px' spacingY='6px'>
            {
              blancs.map((wine, i) => <WineCard key={i} wine={ wine }/>)
            }
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton p='2px 0 2px 2px'>
          <Flex alignItems='center' flex={1} textAlign='left'>
            <Heading as='h4' size='sm' textTransform='uppercase'>Bulles</Heading>
            <Box borderRadius={1000} w='11px' h='11px' ml='10px' backgroundColor='wine.blanc'></Box>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0} pb={4}>
          <SimpleGrid columns={[1,2,3]} width='100%' spacingX='40px' spacingY='6px'>
            {
              bulles.map((wine, i) => <WineCard key={i} wine={ wine }/>)
            }
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton p='2px 0 2px 2px'>
          <Flex alignItems='center' flex={1} textAlign='left'>
            <Heading as='h4' size='sm' textTransform='uppercase'>Rosés</Heading>
            <Box borderRadius={1000} w='11px' h='11px' ml='10px' backgroundColor='wine.rose'></Box>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0} pb={4}>
          <SimpleGrid columns={[1,2,3]} width='100%' spacingX='40px' spacingY='6px'>
            {
              roses.map((wine, i) => <WineCard key={i} wine={ wine }/>)
            }
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton p='2px 0 2px 2px'>
          <Flex alignItems='center' flex={1} textAlign='left'>
            <Heading as='h4' size='sm' textTransform='uppercase'>Rouges</Heading>
            <Box borderRadius={1000} w='11px' h='11px' ml='10px' backgroundColor='primary.main'></Box>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={0} pb={4}>
          <SimpleGrid columns={[1,2,3]} width='100%' spacingX='40px' spacingY='6px'>
            {
              rouges.map((wine, i) => <WineCard key={i} wine={ wine }/>)
            }
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
