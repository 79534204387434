import * as React from "react"
import {
  Box,
  Button,
  Container,
  Flex,
  Menu,
  Input,
  InputGroup,
  MenuButton,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
  InputRightElement,
  SimpleGrid,
  Image, Spacer,
} from '@chakra-ui/react'
import {CloseIcon} from '@chakra-ui/icons'
import {WineDispatcher} from './wine-dispatcher'
import {WineCard} from './wine-card'
import {NoWine} from './no-wine'
import { useState } from 'react'
import {NoResult} from './noResult'
import bio from '../assets/bio.png'

export const Wines = ({ wines, setWines, initialWines, visible }) => {
  const [search, setSearch] = useState('')
  const [filteredWines, setFilteredWines] = useState([])


  const filterWines = (userInput) => {
    const finalInput = userInput.toLowerCase()
    const inputFiltered = wines.filter(wine =>
      wine.title.toLowerCase().includes(finalInput) ||
      wine.domain.toLowerCase().includes(finalInput) ||
      wine.origin.toLowerCase().includes(finalInput)
    )
    setFilteredWines([...inputFiltered])
    setSearch(userInput)
  }

  const filterByPrice = (orderBy) => {
    switch (orderBy) {
      case 'asc':
        const ascWines = wines.sort((a, b) => parseFloat(a.price.away) - parseFloat(b.price.away))
        setWines([...ascWines])
        break
      case 'desc':
        const descWines = wines.sort((a, b) => parseFloat(b.price.away) - parseFloat(a.price.away))
        setWines([...descWines])
        break
      default:
        setWines([...initialWines])
        break
    }
  }

  const clearSearch = () => {
    setFilteredWines([])
    setSearch('')
  }

  return (
    <>
      <Box position='fixed' bottom='0' backgroundColor='white' p='10px' width='100%'>
        <Flex>
          Bio
          <Image p='3px' src={bio} alt='Vin bio pic saint loup' width='20px' />
          <Spacer />
          <Box fontWeight='500' fontSize='sm'>À emporter &bull; Sur place</Box>
        </Flex>
        <Flex gap='4px' justifyContent='center'>
          <InputGroup size='md' backgroundColor='white' maxW='450px'>
            <Input placeholder='Cuvée, domaine, origine...' size='sm' mb='6px' value={search} onChange={event => filterWines(event.target.value)} />
            <InputRightElement fontSize='sm' h='1.80rem' children={<CloseIcon onClick={clearSearch} color='gray.500' />} />
          </InputGroup>
          <Menu closeOnSelect={true} autoSelect={false}>
            <MenuButton as={Button} size='sm' minW='80px' colorScheme='primary'>
              Trier
            </MenuButton>
            <MenuList>
              <MenuOptionGroup backgroundColor='white' defaultValue='none' title='Order' type='radio'>
                <MenuItemOption value='none' onClick={(e) => filterByPrice('none')}>Aucun</MenuItemOption>
                <MenuItemOption value='asc' onClick={(e) => filterByPrice('asc')}>Prix croissants</MenuItemOption>
                <MenuItemOption value='desc' onClick={(e) => filterByPrice('desc')}>Prix décroissants</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Container as='main' width="100%" maxW="1200px" centerContent={true} mt='85px' mb='90px' overflow='hidden' backgroundColor='white'>
        {
          wines.length === 0
          ? <NoResult type='wine' />
          : !search
            ? <WineDispatcher wines={ wines } />
            : <SimpleGrid columns={[1,2,3]} width='100%' spacingX='40px' spacingY='6px'>
              {
                filteredWines.length > 0
                  ? filteredWines.map((wine, i) => <WineCard key={i} wine={ wine }/>)
                  : <NoWine />
              }
            </SimpleGrid>
        }
      </Container>
    </>
  )
}

