import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    primary: {
      main: "#c8115c",
      50: "#c8115c",
      100: "#c8115c",
      200: "#c8115c",
      300: "#c8115c",
      400: "#c8115c",
      500: "#c8115c",
      700: "#c8115c",
      800: "#c8115c",
      900: "#c8115c"
    },
    wine: {
      rouge: '#c8115c',
      rose: '#F4C4BB',
      blanc: '#EEEDC4'
    },
  }
})

export default theme
